import styled from '@emotion/styled';
import { ArrowDropDown, ArrowDropUp, Text, colors } from '@innovationdepartment/proxima-ui';

const PercentChangeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
`;

const BrandChangeContainer = styled.div`
  background-color: ${colors.neutral5};
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 4px;
`;

const ChangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 4px;
`;

type PercentChangeContainerProps = {
  brandChange: string;
  flavorCategoryChange: string;
  brandArrowDirection: string;
  flavorArrowDirection: string;
};

const PercentChange = (props: PercentChangeContainerProps) => {
  const { brandChange, flavorCategoryChange, brandArrowDirection, flavorArrowDirection } = props;

  const BrandArrowComponent = brandArrowDirection === 'up' ? ArrowDropUp : ArrowDropDown;
  const FlavorArrowComponent = flavorArrowDirection === 'up' ? ArrowDropUp : ArrowDropDown;

  return (
    <PercentChangeContainer>
      <ChangeContainer>
        {flavorCategoryChange === '-' ? <></> : <FlavorArrowComponent />}
        <Text variant="table1">{flavorCategoryChange}</Text>
      </ChangeContainer>
      {brandChange === '-' ? (
        <ChangeContainer>
          <Text variant="body2Medium">{brandChange}</Text>
        </ChangeContainer>
      ) : (
        <BrandChangeContainer>
          <BrandArrowComponent />
          <Text variant="body2Medium">{brandChange}</Text>
        </BrandChangeContainer>
      )}
    </PercentChangeContainer>
  );
};

export default PercentChange;
